import React, { useState, useEffect } from "react";
import { Listbox } from "@headlessui/react";
import TempArchive from "./TempArchive";

import { getArchives } from "../services";

function Archives(props) {
  const { category, year } = props.match.params;
  const [archive, setArchive] = useState([]);
  const program = [
    { id: 1, name: "All programs", cat: "program" },
    { id: 2, name: "Saturdays @ Swastika", cat: "Saturdays At Swastika" },
    { id: 3, name: "Music By The Lake", cat: "Music By The Lake" },
    { id: 4, name: "Kalakrida", cat: "Kalakrida" },
    { id: 5, name: "Workshops", cat: "Workshops" },
    { id: 6, name: "Know Your Art", cat: "Know Your Art" },
    { id: 7, name: "Other Programs", cat: "Other Programs" },
  ];

  // generating years from 2015 to current year
  const currentYear = new Date().getFullYear();
  const yearO = [];
  let tempy = [];
  for (let i = currentYear; i >= 2015; i--) {
    tempy.push(i);
  }
  tempy.forEach((year, index) => {
    yearO.push({ id: index + 1, name: year.toString() });
  });

  // forcing selection of category and year using url params if any
  function choice(category = "program") {
    switch (category) {
      case "Saturdays At Swastika":
      case "Saturdays @ Swastika":
      case "Saturdays":
      case "Saturdays@Swastika":
      case "saturdaysatswastika":
        return program[1];
      case "Music By The Lake":
      case "MusicByTheLake ":
      case "music by the lake":
      case "musicbythelake":
        return program[2];
      case "Kalakrida":
      case "kalakrida":
        return program[3];
      case "Know Your Art":
      case "KnowYourArt":
      case "knowyourart":
      case "know your art":
        return program[5];
      default:
        return program[0];
    }
  }
  function chooseYear(year = currentYear.toString()) {
    return yearO.find((y) => y.name === year);
  }

  const [selectedProgram, setSelectedProgram] = useState(
    category ? choice(category) : program[0]
  );
  const [selectedYear, setSelectedYear] = useState(
    year ? chooseYear(year) : yearO[0]
  );
  const getData = async () => {
    let year = +selectedYear.name;
    let category = selectedProgram.cat;
    const result = await getArchives(year, category);
    setArchive(result);
  };
  useEffect(() => {
    getData();
  }, [selectedProgram, selectedYear]);
  let currentMonth;
  return (
    <div class="">
      <div className="md:ml-5 font-montserrat">
        <div class="container-fluid justify-content-center px-0">
          <div className="md:py-5 py-4 md:pl-64 md:pr-32 xs:px-5 xs:pl-10 justify-start">
            <div className="">
              <div className="flex flex-col justify-start font-medium md:text-xl text-base mb-3 -ml-2">
                <div className="flex justify-between font-semibold">
                  <span className="md:-ml-0 md:pb-0 pb-3">ARCHIVES</span>
                  <div>
                    <span
                      className="pr-2 absolute md:right-48 right-16 md:mt-0 mt-4  text-right"
                      style={{ zIndex: "5" }}
                    >
                      <Listbox
                        as="div"
                        value={selectedProgram}
                        className="listBox  z-2  text-base max-w-6 "
                        onChange={setSelectedProgram}
                      >
                        <Listbox.Button className="focus:outline-none md:mb-1 font-semibold">
                          {selectedProgram.name + " ▾"}
                        </Listbox.Button>
                        <Listbox.Options className="dropOpC  relative bg-ahum-brown-collage md:-right-10 md:pt-1 rounded-md hover:cursor-pointer text-lg">
                          {program.map((person) => (
                            <Listbox.Option
                              className="dropOptions py-1.5 px-2.5 text-base"
                              key={person.id}
                              value={person}
                            >
                              {person.name}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Listbox>
                    </span>

                    <span className="text-right  absolute md:right-20 right-4 md:mt-0 mt-4">
                      <Listbox
                        as="div"
                        value={selectedYear}
                        onChange={setSelectedYear}
                        className="listBox  text-base"
                      >
                        <Listbox.Button className="font-semibold focus:outline-none md:mb-1">
                          {selectedYear.name + " ▾"}
                        </Listbox.Button>
                        <Listbox.Options className="dropOpC font-sans relative bg-ahum-brown-collage opacity-90 rounded-md -right-1 ">
                          {yearO.map((person) => (
                            <Listbox.Option
                              className="dropOptions py-1.5 px-2.5 md:text-base"
                              key={person.id}
                              value={person}
                            >
                              {person.name}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Listbox>
                    </span>
                  </div>
                </div>
              </div>

              <span className="md:ml-2.5 ml-1 text-2xl font-medium">
                {selectedYear.name}
              </span>
            </div>
            <div className="flex flex-row h-full w-full mt-18  md:ml-4 ml-2.5">
              <div
                className="bg-black  ml-3 archiveLine"
                style={{
                  width: "2.5px",
                  marginRight: 1,
                  // make a css file and put mediaquery fr phones marginright:3
                }}
              ></div>
              <div className="my-5 md:pt-0 pt-16 ">
                <div>
                  {archive.map((program) => {
                    let dispMonth = true;
                    if (currentMonth === program.date.split("-")[1])
                      dispMonth = false;
                    else currentMonth = program.date.split("-")[1];
                    return (
                      <TempArchive program={program} dispMonth={dispMonth} />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Archives;
