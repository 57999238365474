import { request, gql } from "graphql-request";
const graphqlAPI = process.env.REACT_APP_GRAPHCMS_API;

// function for mutations
const fetcher = async (query) => {
  await fetch(graphqlAPI, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${process.env.REACT_APP_GRAPHCMS_PASS}`,
    },
    body: JSON.stringify({ query }),
  })
    .then((res) => res.json())
    .then((res) => {
      console.log(res);
    })
    .catch((err) => console.error(err));
};

// Getting all shows
export const getUpcomingShows = async () => {
  const query = gql`
    query MyQuery {
      upcomingShows (orderBy:dateAndTime_ASC){
        id
        category {
          categoryName
        }
        dateAndTime
        programTitle
        posterImage {
          url
        }
        location
      }
    }
  `;
  const result = await request(graphqlAPI, query);
  return result.upcomingShows;
};
export const getArchives = async (year, category) => {
  const query = gql`
    query MyQuery {
      archives(
        where: { date_gt: "${year - 1}-12-31", date_lt: "${year + 1}-01-01",}
  first:100
        orderBy: date_ASC
      ) {
        category {
          categoryName
        }
        event
        imgLink
        date
        artist
        img {
          url
        }
      }
    }
  `;
  const queryCat = gql`
  query MyQuery {
    archives(
      where: { date_gt: "${year - 1}-12-31", date_lt: "${
    year + 1
  }-01-01",category: {categoryName: "${category}"}
 }
    first:100
      orderBy: date_ASC
    ) {
      category {
        categoryName
      }
      event
      imgLink
      date
      artist
      img {
        url
      }
    }
  }
`;
  let result;
  if (category === "program") result = await request(graphqlAPI, query);
  else result = await request(graphqlAPI, queryCat);
  return result.archives;
};
// Getting only one show
export const getUpcomingShow = async (id) => {
  const query = gql`
    query MyQuery {
  upcomingShow(where: {id: "${id}"}) {
    id
    programTitle
  }
}
  `;
  const result = await request(graphqlAPI, query);
  return result.upcomingShow;
};

// Adding RSVP to a show
export const rsvp = async (rsvpData) => {
  const query = `mutation MyMutation {
    createRsvp(
      data: {
        email: "${rsvpData.email}",
          name: "${rsvpData.name}",
          numberOfAttendees: ${rsvpData.attendees},
          phoneNumber: ${rsvpData.phone},
          eventName: "${rsvpData.title}",
          upcomingShow: { connect: { id: "${rsvpData.event}" } }
      }
    ) {
      id
    }
  }`;
  await fetcher(query);
  console.log("RSVP sent");
};

// Contact and Book Our Space form Mutations
export const contactMutation = async (contactData) => {
  const query = `mutation MyMutation {
    createContact(
      data: {
        name: "${contactData.name}", 
        mail: "${contactData.mail}", 
        bookOurSpace: ${contactData.bookOurSpace}, 
        message: "${contactData.message}", 
        phone: ${contactData.phone}}
    ) {
      id
    }
  }`;
  await fetcher(query);
  console.log("Contact sent");
};

// Newsletter Subscription Mutations
export const newsletterMutation = async (mail) => {
  const query = `mutation MyMutation {
    createNewsletter(
      data: {mail: "${mail}"}
    ) {
      id
    }
  }`;
  await fetcher(query);
  console.log("Newsletter sent");
};
