import React from "react";

export default function TempArchive(props) {
  const { program, dispMonth } = props;
  const year = program.date.slice(0, 4);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <div
      data-aos="slide-up"
      data-aos-duration="700"
      className="font-montserrat md:grid  xs:pl-5 md:ml-0 xs:ml-0.5 md:pl-0  grid-cols-12 gap-4 items-center py-2 flex-shrink-0 mb-10  ml-0 mr-0 "
    >
      <div
        className="rounded-full flex bg-black md:h-3 md:w-3 h-4 w-4 col-span-1  archive-dot md:-mt-4"
        // md:-ml-2 xs:-ml-8
      ></div>
      <div className="col-span-2 pl-1.5 font-normal text-lg xs:pb-3  md:pb-0 -mt-5  md:-mt-0 md:mb-2 mb-3">
        {dispMonth && months[parseInt(program.date.split("-")[1], 10) - 1]}
        {
          // eslint-disable-next-line
          program.date.split(" ").length === 2 && program.date.split(" ")[0]
        }
      </div>
      {program && (
        <div className="md:col-span-5 col-span-4 mr-10">
          <img
            className="archiveImages md:mb-0 mb-4"
            alt="archives"
            src={
              program.img
                ? program.img.url
                : program.imgLink
                ? program.imgLink
                : "https://via.placeholder.com/310x225?text=No Image Available"
            }
          />
        </div>
      )}
      <div
        className={
          program.category.categoryName.toLowerCase() === "kalakrida"
            ? window.innerWidth < 767
              ? "col-span-4 text-center text-base md:mr-0 mr-5 ml-10"
              : "col-span-4 text-center text-base md:mr-0 mr-5"
            : "col-span-4 text-center text-base md:mr-0 mr-5"
        }
      >
        <span
          id="kalakridaMargin"
          className="py-1 block text-lg font-semibold   text-center"
        >
          ‘
          {program.category.categoryName.toLowerCase() === "kalakrida"
            ? "KALAKRIDA - " + year
            : program.event.toUpperCase()}
          ’
        </span>
        {program.artist && (
          <span className="py-1 block font-normal text-base text-center   ">
            {program.artist.split(",").length > 1 ? "Artists : " : "Artist : "}
            {program.artist}
          </span>
        )}
        <span className="py-1 block font-normal text-base leading-5 text-center">
          {program.category.categoryName.toLowerCase() === "kalakrida"
            ? ""
            : program.category.categoryName}
        </span>
        {program && (
          <span className="py-1 mb-2 block font-normal text-base leading-5 text-center">
            {program.date && program.date.split("-").reverse().join("-")}
          </span>
        )}
        {program.instagram && (
          <a href={program.instagram} className="px-3 py-3">
            <i class="fab fa-instagram"></i>
          </a>
        )}
        {program.youtube && (
          <a href={program.youtube} className="px-3 py-3">
            <i class="fab fa-youtube"></i>
          </a>
        )}
        {program.othLink && (
          <a href={program.othLink} className="px-3 py-2 ">
            <i class="fas fa-globe-americas"></i>{" "}
          </a>
        )}
        {(program.category.categoryName.toLowerCase() === "kalakrida" ||
          program.galleryEnable === true) && (
          <div className="col-span-4 text-center font-normal underline">
            <a
              href={
                `/details/${program.category.categoryName.toLowerCase()}/` +
                year
              }
            >
              View more
            </a>
          </div>
        )}
      </div>
    </div>
  );
}
